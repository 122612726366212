.uploadbundle {
  .flexrows {
    .category_and_tags {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    .genretags {
      p {
        margin: 0;
      }
      .genreimg {
        display: flex;
        align-items: center;
      }
      .dropdownselector {
        display: flex;
        align-items: center;
      }
    }
    .addcourses {
      display: inline-flex;
      margin: 1vw 0vw;
      width: 100%;
      min-height: 3vw;
      outline: none;

      .dropdownselector {
        width: 100%;
        display: flex;
        align-items: center;
        div:nth-child(1) {
          width: 100%;
        }
      }
    }
  }
}
