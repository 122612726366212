.uploadsinglepath {
  .flexrows {
    .flexrows-content {
      width: 100%;
    }
    .select-container {
      .genreimg {
        p {
          margin: 0;
        }
      }
      .dropdownselector {
        width: 100%;
        display: flex;
        align-items: center;

        > div {
          width: 100%;
        }
        .ReactDropdownSelect {
          width: 100%;
        }
      }
    }
    .flexrows-content {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .flexrows-content-courses {
      grid-template-columns: 1fr 1fr ;
      display: grid;
 
      .select-container{
       align-items: start;
      }
     }

     .css-sbbpcf-DropDown {
      width: fit-content;
     }
  }

  .flexrows:nth-child(6) {
    padding: 1%;
    .container-csv-file {
      border: 2px dotted #028181;
      width: 100%;
      min-height: 150px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      a{
        font-weight:700;
        color: #008181;
      }

      p {
        margin: 0;
        padding: 0;
      }

      .upload-csv-file,
      .delete-csv-file {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .upload-csv-file {
        gap: 10px;
        input {
          background: #008181;
          color: #fff;
          border-radius: 0.5vw;
          border: 0;
          padding: 5px;
          font-family: "TelegrafUltraBold";
        }
      }

      .delete-csv-file {
        .btn-delete-file {
          background: #008181;
          color: #fff;
          border-radius: 0.5vw;
          border: 0;
          padding: 5px;
          font-family: "TelegrafUltraBold";
        }
      }
    }
  }
}
