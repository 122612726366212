.uploadcontent {
  width: 100%;
  padding: 1% 3%;
  overflow: scroll;

  .search-box {
    display: flex;
    align-items: center;
    gap: 5px;
    padding-bottom: 20px;

    p {
      font-size: 20px;
      margin: 0;
      color: #004a4a;
      font-weight: 700;
    }

    .search {
      width: 13vw;
      height: 2vw;
      border: 1px solid #008181;
      border-radius: 0.2vw;
      font-size: 15px;
      padding: 10px 10px;
      outline: none;
    }
  }

  .box-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.25rem;

    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;

    .box-item {
      color: grey;
      width: 2.5rem;
      height: 2.5rem;
      cursor: pointer;

      :hover {
        color: #004a4a;
      }
      justify-content: center;
      padding: 16px;
      display: inline-flex;
      align-items: center;
      gap: 8px;
    }

    .box-pages {
      width: 2.5rem;
      height: 2.5rem;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }

    .box-current-page {
      background-color: #b3e6e6;
      padding-left: 8px;
      padding-right: 8px;
      padding-top: 10px;
      padding-bottom: 10px;
      justify-content: center;
      align-items: center;
    }

    .box-next {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .input-resource {
    display: none;
  }

  .upload-info-file-container {
    display: flex;
    gap: 10px;
    align-items: center;
    border: 2px solid #028181;
    padding: 10px;
    border-radius: 12px;

    span {
      border: 2px solid white;
      padding: 5px 15px;
      border-radius: 12px;
      background-color: #008181;
      color: white;
      font-weight: 500;
      cursor: pointer;
    }
  }

  .upload-info-file-container {
    display: flex;
    gap: 10px;
    align-items: center;
    border: 2px solid #028181;
    padding: 10px;
    border-radius: 12px;

    span {
      border: 2px solid white;
      padding: 5px 15px;
      border-radius: 12px;
      background-color: #008181;
      color: white;
      font-weight: 500;
      cursor: pointer;
    }
  }

  .input-resource {
    display: none;
  }

  .upload-file {
    display: flex;
    align-items: center;
    .upload-file-ref {
      display: flex;

      align-items: center;
      justify-content: flex-start;
      border: 1px solid #008181;
      border-radius: 12px;
      padding: 6px 12px;
      background-color: white;

      p {
        margin: 0px !important;
        color: #008181;
        font-size: 22px;
        font-family: TelegrafUltraBold;
        padding: 11px 15px;

        width: fit-content;
        white-space: nowrap;
      }

      .upload-zip-contests {
        border-radius: 12px;
        padding: 12px 20px 12px 20px;
        background-color: white;
        border-color: #028181;
        border: 1px solid;
        color: #028181;
        font-weight: 700;
        cursor: pointer;
      }
    }
  }

  .mainheading {
    font-size: 40px;
    color: #585858;
    font-family: "TelegrafUltraBold";
  }

  .uploadcontentlist {
    margin: 4vw;
    display: flex;
    justify-content: space-around;

    .uploadcontenttiles {
      width: 30%;
      height: 6vw;
      border-radius: 1vw;
      color: #004a4a;
      background: #b3e6e6;
      font-family: "TelegrafUltraBold";
      font-size: 2.5rem;
      text-align: center;
      padding: 10px;
      position: relative;
      box-shadow: 0px 10px 16px #00000029;

      .vertical-center {
        margin: 0;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
        width: fit-content;

        img {
          padding-right: 10px;
        }
      }
    }
    :hover {
      color: #fff;
      background: #004a4a;
    }
  }

  .uploadcontentlisthorizontal {
    margin: 4vw 2vw;

    .uploadcontenttiles2 {
      width: 100%;
      height: 5vw;
      border-radius: 1vw;
      color: #004a4a;
      background: #efe9e9;
      font-family: "TelegrafUltraBold";
      font-size: 2.5rem;
      padding: 10px;
      position: relative;
      margin: 1vw 0;
      box-shadow: 0px 10px 16px #00000029;
      .divcenter {
        margin: auto;
        padding: 10px;
        border: 0 !important;
      }
    }

    :hover {
      border: 5px solid #029b9be3;
    }
  }

  .selectedsection {
    .uploadcontentsection {
      width: 100%;

      .pageheading {
        margin-top: 3vw;
        display: flex;
        h2 {
          font-size: 40px;
          color: #585858;
          font-family: "TelegrafUltraBold";
          display: flex;

          p {
            color: #004a4a;
            margin-right: 15px;
            font-family: TelegrafRegular;
            font-size: 40px;
          }
        }

        .mainbuttons {
          color: #fff;
          margin-left: auto;
          font-family: TelegrafUltraBold;
          font-size: 22px;

          .create {
            width: 8vw;
            height: auto;
            padding: 5px;
            background: #b9dddd;
            box-shadow: 0px 4px 12px #0000001c;
            border-radius: 1vw;
            opacity: 1;
            border: 0;
            color: #004a4a;
          }
          .bulk {
            width: 5vw;
            height: auto;
            padding: 5px;
            background: #004a4a;
            box-shadow: 0px 4px 12px #0000001c;
            border-radius: 1vw;
            opacity: 1;
            border: 0;
            color: #fff;
            margin: 1vw;
          }
        }
      }
    }
  }
}
