.uploadcharts {
  .upload-file,
  .upload-info-file-container {
    margin: 1vw;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  background: #efe9e9 0% 0% no-repeat padding-box;
  box-shadow: 0px 11px 21px #00000029;
  border-radius: 30px;
  opacity: 1;
  height: max-content;
  padding: 1%;

  .heading {
    color: #004a4a;
    font-size: 35px;
    font-family: TelegrafUltraBold;
  }

  .textfieldedituser {
    width: 98%;
    height: 3vw;
    background: #fff;
    box-shadow: 0px 4px 12px #0000001a;
    border-radius: 8px;
    opacity: 1;
    display: flex;
    margin: 1vw;

    label {
      color: #008181;
      font-size: 22px;
      font-family: TelegrafUltraBold;
      padding: 11px 15px;
      width: fit-content;
      white-space: nowrap;
    }

    .divider {
      background: #0000001a;
      width: 1px;
    }

    input {
      border: none;
      outline: none;
      color: #858585;
      width: 100%;
      margin: 0 1%;
      font-size: 25px;
      font-family: TelegrafUltraLight;
    }

    input[type="date" i] {
      width: 60% !important;
    }

    .dropdownselector {
      width: 100%;
      border: none;
      outline: none;

      p {
        color: #008181;
        font-size: 18px;
        font-family: TelegrafUltraBold;
        padding: 15px 20px;
      }

      .ReactDropdownSelect {
        box-sizing: border-box;
        position: relative;
        display: flex;
        outline: none !important;
        width: 100%;
        padding: 6px;
        flex-direction: row;
        direction: ltr;
        box-shadow: 0px 4px 12px #0000001c;
        border-radius: 12px;
        align-items: center;
        cursor: pointer;
        min-height: 3vw;
        pointer-events: all;
        border: none;

        .react-dropdown-select-content {
          display: flex !important;
          border: none !important;
          outline: none !important;
          width: 15vw !important;
          flex-wrap: inherit !important;
          align-items: center;
          font-size: 17px;
          color: #366f6f !important;
        }
        .react-dropdown-select-dropdown {
          height: 6vw;
          overflow: scroll;
        }

        .react-dropdown-select-option {
          background-color: #366f6f !important;
          border-radius: 2px !important;
          border: 1px solid #366f6f !important;
          color: #fff !important;
          font-size: 17px !important;
          font-weight: 600 !important;
          width: auto;
          align-items: center;
        }
        .react-dropdown-select-option-label {
          width: max-content !important;
        }

        .react-dropdown-select-item-selected {
          background-color: #366f6f !important;
        }
        .react-dropdown-select-dropdown-handle {
          width: 26px;
          height: 26px;
          color: aliceblue;
        }
      }

      .react-dropdown-select-content {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex !important;
        border: none !important;
        outline: none !important;
        border-radius: 0 0.3vw 0.3vw 0 !important;
        width: 100% !important;
        flex-wrap: inherit !important;
      }
    }

    .gettrack {
      height: auto;
      min-height: 2vw;
      background: #008181;
      color: #fff;
      border-radius: 0.5vw;
      border: 0;
      padding: 8px;
      font-family: "TelegrafUltraBold";
      font-size: 16px;
      margin: 0.5vw;
      display: block;
    }
  }

  .custom-checkbox {
    margin: 1vw;
    font-size: 18px;
    color: #008181;
  }

  .flexrows {
    display: flex;
    align-items: center;
  }

  .textareaedituser {
    width: 98%;
    height: 10vw;
    background: #fff;
    box-shadow: 0px 4px 12px #0000001a;
    border-radius: 8px;
    opacity: 1;
    display: flex;
    margin: 1%;

    label {
      color: #008181;
      font-size: 22px;
      font-family: TelegrafUltraBold;
      padding: 15px 15px;
      width: fit-content;
      white-space: nowrap;
    }

    .divider {
      background: #0000001a;
      width: 1px;
    }

    textarea {
      border: none;
      outline: none;
      color: #858585;
      width: 100%;
      margin: 1%;
      font-size: 25px;
      font-family: TelegrafUltraLight;
      max-height: 10vw;
    }
  }

  .genretags {
    display: inline-flex;
    margin: 1vw 0vw;
    width: 100%;
    border: none;
    outline: none;

    p {
      color: #008181;
      font-size: 18px;
      font-family: TelegrafUltraBold;
      padding: 15px 20px;
    }

    .ReactDropdownSelect {
      box-sizing: border-box;
      position: relative;
      display: flex;
      border: none !important;
      outline: none !important;
      width: 20vw;
      padding: 2px 15px;
      flex-direction: row;
      direction: ltr;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 4px 12px #0000001c;
      border-radius: 25px;
      align-items: center;
      cursor: pointer;
      min-height: 3vw;
      pointer-events: all;

      .react-dropdown-select-content {
        display: flex !important;
        border: none !important;
        outline: none !important;
        width: 100% !important;
        flex-wrap: inherit !important;
        align-items: center;
        font-size: 24px;
        color: #008181 !important;
      }

      .react-dropdown-select-option {
        background-color: #008181 !important;
        border-radius: 2px !important;
        border: 1px solid #008181 !important;
        color: #fff !important;
        font-size: 13px !important;
        font-weight: 600 !important;
        width: auto;
        align-items: center;
      }
      .react-dropdown-select-option-label {
        width: max-content !important;
      }

      .react-dropdown-select-item-selected {
        background-color: #008181 !important;
      }
    }

    .react-dropdown-select-content {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex !important;
      border: none !important;
      outline: none !important;
      border-radius: 0 0.3vw 0.3vw 0 !important;
      width: 100% !important;
      flex-wrap: inherit !important;
    }
  }

  .monitoringfield {
    margin: 1vw;
    display: flex;
    position: relative;
    width: 100%;

    .customlabels {
      height: 2.5vw;
      display: block;
      border-radius: 0.5vw;
      width: 2.5vw;
      border: 2px solid #008181;
      color: #008181;
      font-size: 30px;
      text-align: center;
      margin: 0 4px;
    }

    .customlabelimage {
      height: 2.5vw;
      display: block;
      border-radius: 0.5vw;
      width: 2.5vw;
      border: 3px solid #008181;
      margin: 0 4px;
    }

    .dropdown {
      position: relative;
      display: inline-block;
    }

    .show {
      display: block !important;
    }

    .dropdown-content {
      display: none;
      position: absolute;
      background-color: #f6f6f6;
      height: 10vw;
      overflow: auto;
      padding: 10px;
      bottom: -6vw;
      right: 15vw;
      border: none;
      background: #e4f7f7 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 20px;
      opacity: 1;
      z-index: 1;

      ::placeholder {
        color: #008181 !important;
      }

      input {
        text-align: left;
        font: normal normal 800 22px/27px Telegraf;
        letter-spacing: 0px;
        color: #008181;
        opacity: 1;
        border: 0 !important;
        background: transparent;
        border-bottom: 2px solid #008181 !important;
        user-select: none !important;
        outline: none;
      }
    }

    .dropdown-content p {
      color: #000;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      background-color: #e8e8ed;
      font-family: TelegrafUltraBold;
      font-size: 14px;
      margin: 2px;
    }

    .dropdown-content p:hover {
      background-color: #008181;
      color: #fff;
    }

    .genreimg {
      p {
        color: #008181;
        font-size: 22px;
        font-family: TelegrafUltraBold;
        padding: 15px 20px;
      }
    }
  }

  .sampletrackscontainer {
    border-left: 2px solid #029b9b67;
    .sampletrackstitle {
      display: inline-flex;
      color: #008181;
      font-size: 23px;
      font-family: telegrafUltraBold;
      width: 100%;
      height: 0;
      .detail {
        margin-left: 10px;
        font-family: telegrafUltraLight;
      }
      .dashline {
        color: #029b9b67;
        font-size: 25px;
        margin-right: 10px;
      }
    }
  }
  .uploadbutton {
    height: auto;
    min-height: 2vw;
    background: #008181;
    color: #fff;
    border-radius: 0.5vw;
    border: 0;
    padding: 10px;
    font-family: "TelegrafUltraBold";
    font-size: 18px;
    margin: 1vw;
    margin-left: auto;
    display: block;
  }

  .addtrackbutton {
    height: auto;
    min-height: 2vw;
    background: #008181;
    color: #fff;
    border-radius: 0.5vw;
    border: 0;
    padding: 10px;
    font-family: "TelegrafUltraBold";
    font-size: 18px;
    margin: 1vw;
    display: block;
    width: max-content;
  }

  table {
    width: 97%;
    margin: 0 1vw;
  }

  thead {
    // width: 100%;
    margin-top: 2vw;
    tr {
      text-align: left;
      font-family: TelegrafUltraBold;
      letter-spacing: 0px;
      color: #004a4a;
      opacity: 1;
      height: auto;
      background: #ffffff88 0% 0% no-repeat padding-box;
      border: 4px solid #029b9be3;
      border-radius: 8px;
      opacity: 1;
    }

    th {
      padding: 0.4vw 1vw;
      border-right: 2px solid #004a4a;
    }
  }

  tr {
    width: 100%;
    height: 50px;
    background: transparent;
    border-radius: 8px;
    display: table-row !important;
    opacity: 1;
    display: table;
    margin: 0.5vw 0;
    border-top: 2px solid #585858;
    border-bottom: 2px solid #585858;

    td {
      padding: 0vw 1vw;
      border-right: 2px solid #004a4a;
    }

    .rowdata1 {
      width: 30%;
      color: #004a4a;
      font-size: 16px;
      font-family: TelegrafUltraBold;
      border-right: 2px solid #004a4a !important;
    }
    .rowdata2 {
      width: 35%;
      color: #004a4a;
      font-size: 16px;
      font-family: TelegrafUltraBold;
    }
    .rowdata3 {
      width: 35%;
      color: #004a4a;
      font-size: 16px;
      font-family: TelegrafUltraBold;
      border: 0;
    }
  }

  .coverimageselector {
    width: 90%;
    height: 10vw;
    background: #b9dddd 0% 0% no-repeat padding-box;
    border: 10px dashed #008181;
    opacity: 1;
    margin: 1vw auto;

    .custom-file-upload {
      width: 100%;
      height: 100%;
      border: 0px dashed #029b9b31;
      opacity: 1;
      color: #fff;
      font-family: TelegrafUltraBold;
    }

    p {
      font-size: 30px;
      color: #008181;
      font-family: TelegrafUltraBold;
      margin: auto;
      transform: translate(40%, 100%);
    }

    input[type="file"] {
      display: none;
    }

    .uploadsymbol {
      transform: translate(0%, -50%);
      position: relative;
      top: 50%;
    }
    .uploadedimage {
      width: 100%;
      height: 100%;
    }
  }
}
