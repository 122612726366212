.uploadcontests {
  .css-i0eu9c-DropDown {
    width: fit-content !important;
  }

  .resources {
    margin-top: 50px;

    display: flex;
    flex-direction: column;
    gap: 20px;

    p {
      cursor: pointer;
      font-size: 20px;
      color: white;
      font-weight: 700;
    }

    .resources-header {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
  .resources-inputs {
    display: flex;
    flex-direction: column;
    gap: 10px;

    input {
      flex: 1;
      border: none;
      outline: none;
      color: #858585;
      width: 100% !important;
      padding-left: 10px;
      font-size: 25px;
      font-family: TelegrafUltraLight;
      border-radius: 8px;
    }

    .input-state-container {
      color: #008181;
      font-size: 18px;
      font-family: TelegrafUltraBold;
      padding: 8px 12px;
      min-width: -moz-fit-content;
      min-width: fit-content;
      display: flex;
      align-items: center;
      margin: 0;
      background-color: white;
      border-radius: 12px;
      gap: 20px;

      .input-state-name {
        flex: 1 1 0%;
        border-left: 2px solid;
        padding-left: 20px;
      }

      .btn-input-state {
        display: flex;
        gap: 12px;
        align-items: center;

        .btn-input-state-edit,
        .btn-input-state-delete {
          color: #008181;
          cursor: pointer;
        }
      }
    }
  }
}
