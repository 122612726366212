.contentlisttable {
  tr {
    .rowdata1_pathmanagment {
      width: 60% !important;

      p {
        margin: 0;
      }
    }

    button {
      border: 2px solid #004a4a;
      border-radius: 20px;
      padding: 2px 15px 2px 15px;
      background-color: #fff;
      color: #004a4a;
    }

    button:hover {
      background-color: #004a4a;
      color: #fff;
      border: 2px solid #ffff;
    }

    .rowdata2_pathmanagment {
      width: 100% !important;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      gap: 20px;
      border-right: none;


    }
  }
}
