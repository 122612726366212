.uploadcourses{
    .tags_boundles_courses_list{
        display: block;

        .dropdownselector{
            .ReactDropdownSelect{
                width: 100%;
            }
        }
    }
}