.partnerheading {
  font-size: 30px;
  color: #004a4a;
  font-family: TelegrafUltraBold;
}
.createpartner {
  background: #e5ecec 0% 0% no-repeat padding-box;
  box-shadow: 0px 11px 21px #00000029;
  border-radius: 30px;
  opacity: 1;
  padding: 2%;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid #fff;
    -webkit-text-fill-color: #858585 !important;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .flexrowsoutside {
      display: flex;
      padding: 0 2%;
    }

    .textfieldedituser {
      width: 98%;
      background: #fff;
      box-shadow: 0px 4px 12px #0000001a;
      border-radius: 8px;
      opacity: 1;
      display: flex;
      margin: 1%;

      label {
        color: #008181;
        font-size: 16px;
        font-family: TelegrafUltraBold;
        padding: 15px 15px;
        width: inherit;
        max-width: max-content;
        border-right: 1px solid #239292;
      }

      input {
        border: none;
        outline: none;
        color: #858585 !important;
        width: 100%;
        margin: 0 1%;
        font-size: 25px;
        font-family: TelegrafUltraLight;
      }

      .dropdownselector {
        width: 100%;
        border: none;
        outline: none;

        p {
          color: #008181;
          font-size: 18px;
          font-family: TelegrafUltraBold;
          padding: 15px 20px;
        }

        .ReactDropdownSelect {
          box-sizing: border-box;
          position: relative;
          display: flex;
          outline: none !important;
          width: 100%;
          padding: 6px;
          flex-direction: row;
          direction: ltr;
          // background: #366F6F 0% 0% no-repeat padding-box;
          box-shadow: 0px 4px 12px #0000001c;
          border-radius: 12px;
          align-items: center;
          cursor: pointer;
          min-height: 3vw;
          pointer-events: all;
          border: none;
          // margin: 1%;

          .react-dropdown-select-content {
            display: flex !important;
            border: none !important;
            outline: none !important;
            width: 15vw !important;
            flex-wrap: inherit !important;
            align-items: center;
            font-size: 17px;
            color: #fff !important;
            overflow: auto;
          }
          .react-dropdown-select-dropdown {
            height: 6vw;
            overflow: scroll;
          }

          .react-dropdown-select-option {
            background-color: #366f6f !important;
            border-radius: 2px !important;
            border: 1px solid #366f6f !important;
            color: #fff !important;
            /* font-family: sans-serif; */
            font-size: 17px !important;
            font-weight: 600 !important;
            /* margin-bottom: 5px; */
            /* margin-right: 5px; */
            /* padding: 3px !important; */
            width: auto;
            align-items: center;
          }
          .react-dropdown-select-option-label {
            width: max-content !important;
          }

          .react-dropdown-select-item-selected {
            background-color: #366f6f !important;
          }
          .react-dropdown-select-dropdown-handle {
            width: 26px;
            height: 26px;
            color: aliceblue;
          }
        }

        .react-dropdown-select-content {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex !important;
          border: none !important;
          outline: none !important;
          border-radius: 0 0.3vw 0.3vw 0 !important;
          width: 100% !important;
          flex-wrap: inherit !important;
        }
      }
    }

    .genretagsroyalty {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        color: #008181;
        font-size: 17px;
        font-family: TelegrafUltraBold;
        padding: 0px 8px;
        margin: 0px;
      }

      .ReactDropdownSelect {
        box-sizing: border-box;
        position: relative;
        display: flex;
        border: none !important;
        outline: none !important;
        width: 15vw;
        padding: 2px 15px;
        flex-direction: row;
        direction: ltr;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 4px 12px #0000001c;
        border-radius: 25px;
        align-items: center;
        cursor: pointer;
        min-height: 2vw;
        pointer-events: all;

        .react-dropdown-select-content {
          display: flex !important;
          border: none !important;
          outline: none !important;
          width: 100% !important;
          flex-wrap: inherit !important;
          align-items: center;
          font-size: 20px;
          color: #008181 !important;
        }

        .react-dropdown-select-option {
          background-color: #008181 !important;
          border-radius: 2px !important;
          border: 1px solid #008181 !important;
          color: #fff !important;
          /* font-family: sans-serif; */
          font-size: 13px !important;
          font-weight: 600 !important;
          /* margin-bottom: 5px; */
          /* margin-right: 5px; */
          /* padding: 3px !important; */
          width: auto;
          align-items: center;
        }
        .react-dropdown-select-option-label {
          width: max-content !important;
        }

        .react-dropdown-select-item-selected {
          background-color: #008181 !important;
        }
      }

      .react-dropdown-select-content {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex !important;
        border: none !important;
        outline: none !important;
        border-radius: 0 0.3vw 0.3vw 0 !important;
        width: 100% !important;
        flex-wrap: inherit !important;
      }
    }

    .genretagsoutside {
      display: inline-flex;
      margin: 1vw 0vw;
      width: 100%;
      border: none;
      outline: none;

      p {
        color: #008181;
        font-size: 18px;
        font-family: TelegrafUltraBold;
        padding: 15px 20px;
      }

      .ReactDropdownSelect {
        box-sizing: border-box;
        position: relative;
        display: flex;
        border: none !important;
        outline: none !important;
        width: 25vw;
        padding: 2px 15px;
        flex-direction: row;
        direction: ltr;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 4px 12px #0000001c;
        border-radius: 25px;
        align-items: center;
        cursor: pointer;
        min-height: 3vw;
        pointer-events: all;

        .react-dropdown-select-content {
          display: flex !important;
          border: none !important;
          outline: none !important;
          width: 100% !important;
          flex-wrap: inherit !important;
          align-items: center;
          font-size: 24px;
          color: #008181 !important;
        }

        .react-dropdown-select-option {
          background-color: #008181 !important;
          border-radius: 2px !important;
          border: 1px solid #008181 !important;
          color: #fff !important;
          /* font-family: sans-serif; */
          font-size: 13px !important;
          font-weight: 600 !important;
          /* margin-bottom: 5px; */
          /* margin-right: 5px; */
          /* padding: 3px !important; */
          width: auto;
          align-items: center;
        }
        .react-dropdown-select-option-label {
          width: max-content !important;
        }

        .react-dropdown-select-item-selected {
          background-color: #008181 !important;
        }
      }

      .react-dropdown-select-content {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex !important;
        border: none !important;
        outline: none !important;
        border-radius: 0 0.3vw 0.3vw 0 !important;
        width: 100% !important;
        flex-wrap: inherit !important;
      }
    }

    .createpartnerform {
      display: flex;

      .imageselector {
        width: 11vw;
        height: 9vw;
        background: #b9dddd 0% 0% no-repeat padding-box;
        border: 10px dashed #008181;
        opacity: 1;
        margin-right: 1%;
        cursor: pointer;
        .custom-file-upload {
          display: none;
          width: 100%;
          height: 100%;
          border: 0px dashed #029b9b31;
          opacity: 1;
          color: #fff;
          font-family: TelegrafUltraBold;
        }

        p {
          font-size: 22px;
          color: #fff;
          font-family: TelegrafUltraBold;
          text-align: center;
          width: 150px;
          text-align: justify;
          margin: 0 1%;
        }

        input[type="file"] {
          display: none;
        }

        .uploadsymbol {
          transform: translate(0%, -50%);
          position: relative;
          top: 50%;
        }
        .uploadedimage {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .textfieldedituser {
        background: #fff;
        box-shadow: 0px 4px 12px #0000001a;
        border-radius: 8px;
        opacity: 1;
        display: flex;
        align-items: center;
        margin: 1%;

        label {
          color: #008181;
          font-size: 16px;
          font-family: TelegrafUltraBold;
          padding: 15px 15px;
          width: inherit;
          max-width: max-content;
          border-right: 1px solid #239292;
        }

        input {
          border: none;
          outline: none;
          color: #858585;
          width: 100%;
          margin: 0 1%;
          font-size: 18px;
          font-family: TelegrafUltraLight;
        }
      }

      .flexrows {
        display: flex;
        align-items: center;
      }

      .textareaedituser {
        width: 100%;
        height: 10vw;
        background: #fff;
        box-shadow: 0px 4px 12px #0000001a;
        border-radius: 8px;
        opacity: 1;
        display: flex;

        label {
          color: #008181;
          font-size: 22px;
          font-family: TelegrafUltraBold;
          padding: 7% 15px;
          width: -moz-fit-content;

          width: inherit;
          max-width: max-content;
          border-right: 1px solid #239292;
        }

        textarea {
          border: none;
          outline: none;
          color: #858585;
          width: 100%;
          margin: 1%;
          font-size: 25px;
          font-family: TelegrafUltraLight;
          max-height: 10vw;
        }
      }

      .genretags {
        margin: 1vw 0vw;
        width: 100%;
        border: none;
        outline: none;
        display: flex;
        align-items: baseline;

        p {
          color: #008181;
          font-size: 18px;
          font-family: TelegrafUltraBold;
          padding: 0px 8px;
          margin: 0px;
        }

        .ReactDropdownSelect {
          box-sizing: border-box;
          position: relative;
          display: flex;
          border: none !important;
          outline: none !important;
          width: 20vw;
          padding: 2px 15px;
          flex-direction: row;
          direction: ltr;
          background: #ffffff 0% 0% no-repeat padding-box;
          box-shadow: 0px 4px 12px #0000001c;
          border-radius: 25px;
          align-items: center;
          cursor: pointer;
          min-height: 3rem;
          pointer-events: all;

          .react-dropdown-select-content {
            display: flex !important;
            border: none !important;
            outline: none !important;
            width: 100% !important;
            flex-wrap: inherit !important;
            align-items: center;
            font-size: 24px;
            color: #008181 !important;
            overflow: auto;
          }

          .react-dropdown-select-option {
            background-color: #008181 !important;
            border-radius: 2px !important;
            border: 1px solid #008181 !important;
            color: #fff !important;
            font-size: 13px !important;
            font-weight: 600 !important;
            width: auto;
            align-items: center;
          }
          .react-dropdown-select-option-label {
            width: max-content !important;
          }

          .react-dropdown-select-item-selected {
            background-color: #008181 !important;
          }
        }

        .react-dropdown-select-content {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex !important;
          border: none !important;
          outline: none !important;
          border-radius: 0 0.3vw 0.3vw 0 !important;
          width: 100% !important;
          flex-wrap: inherit !important;
        }
      }
    }

    .generateautomatically {
      font-size: 16px;
      color: #008181;
      font-family: "TelegrafUltraBold";
      position: absolute;
      right: 7%;
    }

    .sendinvitation {
      background: #004a4a 0% 0% no-repeat padding-box;
      box-shadow: 0px 4px 12px #0000001c;
      border-radius: 0.6vw;
      opacity: 1;
      border: 0;
      color: #fff;
      width: 10vw;
      height: 3vw;
      font-family: "TelegrafUltraBold";
      position: relative;
      left: 84%;
      margin-top: 2vw;
    }

    .royaltiesheader {
      background: #239292 0% 0% no-repeat padding-box;
      border-radius: 8px;
      opacity: 1;
      display: flex;
      padding: 10px;
      height: 3vw;
      align-items: center;
      display: flex;
      justify-content: space-between;

      h3,
      p {
        color: #fff;
        font-family: "TelegrafUltraBold";
        margin: 0;
      }

      h3 {
        font-size: 30px;
      }

      p {
        font-size: 20px;
      }
    }

    .addroyaltyinputs {
      background: #e5ecec 0% 0% no-repeat padding-box;
      box-shadow: 0px 11px 21px #00000029;
      border-radius: 15px;
      opacity: 1;
      width: 100%;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px 12px;

      .textfieldedituser {
        margin: 0px !important;
        display: flex;
        align-items: center;
        height: max-content;
      }

      .addroyaltybutton {
        background: #004a4a 0% 0% no-repeat padding-box;
        box-shadow: 0px 4px 12px #0000001c;
        border-radius: 0.6vw;
        opacity: 1;
        border: 0;
        color: #fff;
        width: 15vw;
        padding: 5px;
        height: 2vw;
        font-family: "TelegrafUltraBold";
        position: relative;
      }
    }

    table {
      width: 100%;
    }

    thead {
      margin-top: 2vw;
      tr {
        text-align: left;
        font-family: TelegrafUltraBold;
        letter-spacing: 0px;
        color: #004a4a;
        opacity: 1;
        height: auto;
        background: #ffffff88 0% 0% no-repeat padding-box;
        border: 4px solid #029b9be3;
        border-radius: 8px;
        opacity: 1;
      }

      th {
        padding: 0.4vw 1vw;
        border-right: 2px solid #004a4a;
      }
    }

    .selectedroyalty {
      background: #239292 !important;
    }

    tr {
      width: 100%;
      height: 55px;
      background: transparent;
      border-radius: 8px;
      display: table-row !important;
      opacity: 1;
      display: table;
      margin: 0.5vw 0;
      border-top: 2px solid #585858;
      border-bottom: 2px solid #585858;

      td {
        padding: 0vw 1vw;
        border-right: 2px solid #004a4a;
      }

      .rowdata1 {
        color: #004a4a;
        font-size: 16px;
        font-family: TelegrafUltraBold;
        border-right: 2px solid #004a4a !important;
      }
      .rowdata2 {
        color: #004a4a;
        font-size: 16px;
        font-family: TelegrafUltraBold;
      }

      .rowdata3 {
        color: #004a4a;
        font-size: 16px;
        font-family: TelegrafUltraBold;
        border: 0;
      }
    }
  }
}
