.royalties-hystory-table {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .history_row1,
  .history_row2,
  .history_row3,
  .history_row4,
  .history_row5{
    width: 15% !important;
  }
  .history_row6,
  .history_row7{
    width: 10% !important;
  }

  .uploadbutton {
    align-self: flex-end;
    background-color: #004a4a;
    color: #ffff;
    font-size: 16px;
    font-weight: 700;
    padding: 8px 12px;
    border-radius: 12px;
    cursor: pointer;
  }

  .pageheading {
    p {
      cursor: pointer;
      font-size: 20px;
      color: #004a4a;
      font-weight: 700;
    }
  }

  .rowdata7_royalties {
    .upload-info-file-container {
      display: flex;
      flex-direction: column;
    }
    .royalties-operation-container {
      display: flex;
      flex-direction: column;
      gap: 8px;
      justify-content: center;
      align-items: center;
    }
  }

  .history-reason-modal {
    max-width: 750px;
    padding: 12px;
    border: 2px solid #004a4a;
    border-radius: 12px;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: #ffff;
    justify-content: center;
    align-items: center;

    .history-reason-oprations {
      display: flex;
      gap: 4px;
      justify-content: flex-end;
      align-items: center;

      .back-btn {
        background: transparent;
        border: none;
        font-size: 16px;
        cursor: pointer;
      }
    }
  }
}
