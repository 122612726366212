.partner-table {
  .rowdata1_partner {
    width: 35% !important;
  }
  .rowdata2_partner {
    width: 35% !important;
    display: flex;
    align-items: center;
  }
.delete-partner {
    cursor: not-allowed !important;
    opacity: 0.5 !important;
  }
}
