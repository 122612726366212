.reset-password-container{
  padding: 16px;
  h2{
    color: #585858;
    font-family: "TelegrafUltraBold";
    font-size: 40px;
  }

  display: flex;
  flex-direction: column;
  gap: 40px;

  .form-reset-password {
    display: flex;
    flex-direction: column;
    gap: 20px;

    button {
      background-color: #008181;
      color: white;
      font-family: "TelegrafUltraBold";
      font-size: 20px;
      padding: 10px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      height: 40px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      &:disabled {
        background-color: #6c757d;
        cursor: not-allowed;
      }

      .spinner {
        position: absolute;
      }
    }

    .spinner {
      width: 20px;
      height: 20px;
      position: relative;

      .double-bounce1, .double-bounce2 {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #fff;
        opacity: 0.6;
        position: absolute;
        top: 0;
        left: 0;
        animation: bounce 2.0s infinite ease-in-out;
      }

      .double-bounce2 {
        animation-delay: -1.0s;
      }
    }

    @keyframes bounce {
      0%, 100% {
        transform: scale(0.0);
      }
      50% {
        transform: scale(1.0);
      }
    }
  }
}

