.selectedsection {
  .uploadcontentsection {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .pageheading {
      h2 {
        margin: 0;
      }
      display: flex;
      flex-direction: column;
      gap: 20px;

      .add_new_tag {
        width: 200px;
        background-color: #004a4a;
        color: white;
        font-weight: bold;
        border-radius: 20px;
        border: none;
        padding: 10px;
        cursor: pointer;
      }
    }

    .contentlisttable {
      .rowdata1 {
        width: 25%;
        p{
            margin: 0;
            cursor: pointer;
        }
        p:hover{
            color: #fff;
        }
      }
      .rowdata3,
      .rowdata4 {
        width: 20%;
        .disabled{
            opacity: 30%;
        }
      }
      .rowdata4 {
        width: 15%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 100%;
        gap: 10px;

        button {
          border-radius: 5px;
          border: 2px solid #004a4a;
          background-color: white;
          color: #004a4a;
        }
      }
      .rowdata2 {
        width: 35%;
        ul {
          overflow-y: scroll;
          height: 100px;
          margin: 0;
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
        }
        ul::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
}
