.uploadhero {
  .flexrows-content {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    
    .genretags {
      width: 100%;

      .hero-category {
        width: 100%;
        > div {
          width: 100%;
          
        }
      }
    }



  }
}
