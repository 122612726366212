.contentlisttable {
  width: 100%;

  thead {
    tr {
      border-bottom: 4px solid #004a4a;
      border-top: 4px solid #004a4a;
      text-align: left;
      font-family: TelegrafUltraBold;
      letter-spacing: 0px;
      color: #004a4a;
      opacity: 1;
      height: auto;
      background: transparent;
      border-radius: none;
    }

    th {
      padding: 0.4vw 1vw;
      border-right: 2px solid #004a4a;
    }
  }

  tr {
    width: 100%;
    height: 65px;
    background: #b3e6e6 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    display: table;
    margin: 0.5vw 0;

    td {
      padding: 0vw 1vw;
      border-right: 2px solid #004a4a;
    }

    .rowdata1,
    .rowdata2,
    .rowdata3,
    .rowdata4,
    .rowdata5 {
      color: #004a4a;
      font-size: 16px;
      font-family: TelegrafUltraBold;
    }

    .rowdata1,
    .rowdata5 {
      width: 20%;
    }

    .rowdata5 {
      width: 20%;
      border: 0 !important;
    }

    .rowdata2,
    .rowdata3 {
      width: 25%;
    }

    .rowdata1,
    .rowdata1_boundle {
      img {
        height: 3vw;
        width: 3vw;
        border-radius: 0.5vw;
        margin: 0 10px;
      }
    }

    .rowdata1_boundle,
    .rowdata2_boundle,
    .rowdata3_boundle,
    .rowdata4_boundle,
    .rowdata5_boundle{
      width: 25%;
    }

    
  }

  .hovercontents {
    z-index: 1;
    display: flex;
    position: absolute;
    flex-direction: column;
    list-style: none;
    left: 92%;
    background-color: #ffffff;
    padding: 0px 20px;
    border-radius: 8px;
    box-shadow: 0px 0.3px 14px rgba(0, 0, 0, 0.07);
    width: 7%;
    border: 1px solid #f1f1f1;
    .menu-link {
      display: flex;
      text-decoration: none;
      cursor: pointer;
      color: #909496;
      font-size: 16px;
      padding-top: 10px;
      font-weight: 600;
    }
    :hover {
      color: #004a4a;
      font-weight: 600;
    }
  }
}
