.royalties {
  width: 100%;
  padding: 1% 3%;
  min-height: 100vh;



  h2 {
    font-size: 40px;
    color: #585858;
    font-family: "TelegrafUltraBold";
  }

  table {
    width: 100%;

    .rowdata1_royalties,
    .rowdata2_royalties,
    .rowdata3_royalties,
    .rowdata4_royalties,
    .rowdata5_royalties {
      color: #004a4a;
      font-size: 16px;
      font-family: TelegrafUltraBold;
      height: 100% !important;
      width: 18%;
    }
  }

  thead {
    tr {
      border-bottom: 4px solid #004a4a;
      border-top: 4px solid #004a4a;
      text-align: left;
      font-family: TelegrafUltraBold;
      letter-spacing: 0px;
      color: #004a4a;
      opacity: 1;
      height: auto;
      background: transparent;
      border-radius: none;
    }

    th {
      padding: 0.4vw 1vw;
      border-right: 2px solid #004a4a !important;
      height: 100%;
    }
  }

  tr {
    width: 100%;
    height: 55px;
    background: #ededed 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    display: table;
    margin: 0.5vw 0;

    td {
      padding: 0vw 1vw;
      border-right: 2px solid #004a4a !important;
    }
    .rowdata4_royalties,
    .rowdata6_royalties,
    .rowdata5_royalties,
    .rowdata7_royalties{
      a,button {
        border-color: #004a4a;
        border: 2px solid #004a4a;
        border-radius: 10px;
        padding: 4px 14px;
        background-color: #ffff;
        font-weight: 700;
        color: #004a4a;
      }

      .history-btn-disabled {
        opacity: 0.6;
        cursor: not-allowed;
        pointer-events: none;
      }
    }

    .rowdata6_royalties {
      text-align: center;
      border: none;
    }
  }
}


.royalties-table-section {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
