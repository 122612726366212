.contentlisttable {
  tr {
    .rowdata1_contests {
      width: 20% !important;

      p {
        margin: 0;
      }
    }

    button {
      border: 2px solid #004a4a;
      border-radius: 20px;
      padding: 2px 15px 2px 15px;
      background-color: #fff;
      color: #004a4a;
      cursor: pointer;
    }

    button:hover {
      background-color: #004a4a;
      color: #fff;
      border: 2px solid #ffff;
    }

    .rowdata2_contests {
      width: 40% !important;
    
    }


    .rowdata3_contests {
        width: 100% !important;
        border-right: none;
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        gap: 4px;
     
      }
  }
}
