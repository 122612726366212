.partner {
  width: 100%;
  padding: 1% 3%;

  min-height: 100vh;

  .pageheading {
    display: flex;
    flex-direction: column;

    h2,
    p {
      color: #585858;
      font-family: "TelegrafUltraBold";
    }

    h2 {
      font-size: 40px;
    }

    p {
      font-size: 20px;
    }

    .mainbuttons {
      color: #fff;
      margin-left: auto;
      font-family: TelegrafUltraBold;
      font-size: 22px;

      .create {
        width: 8vw;
        height: auto;
        padding: 5px;
        background: #b9dddd;
        box-shadow: 0px 4px 12px #0000001c;
        border-radius: 1vw;
        opacity: 1;
        border: 0;
        color: #004a4a;
      }
      .bulk {
        width: 5vw;
        height: auto;
        padding: 5px;
        background: #004a4a;
        box-shadow: 0px 4px 12px #0000001c;
        border-radius: 1vw;
        opacity: 1;
        border: 0;
        color: #fff;
        margin: 1vw;
      }
    }
  }

  table {
    width: 100%;
  }

  thead {
    // width: 100%;
    tr {
      border-bottom: 4px solid #004a4a;
      border-top: 4px solid #004a4a;
      text-align: left;
      font-family: TelegrafUltraBold;
      letter-spacing: 0px;
      color: #004a4a;
      opacity: 1;
      height: auto;
      background: transparent;
      border-radius: none;
    }

    th {
      padding: 0.4vw 1vw;
      border-right: 2px solid #004a4a;
    }
  }
 
  tr {
    width: 100%;
 
    background: #b3e6e6 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    display: table;
    margin: 0.5vw 0;
    display: flex;
    width: 100%;

    td {
      padding: 0vw 1vw;
      border-right: 2px solid #004a4a;
      min-height: 70px;
    }

    .rowdata1 {
      width: 100%;
      color: #004a4a;
      font-size: 16px;
      font-family: TelegrafUltraBold;
      border-right: 1px solid #004a4a;
      display: flex;
      align-items: center;
      gap: 10px;

      cursor: pointer;

      p {
        color: #004a4a;
        font-size: 16px;
        font-family: TelegrafUltraBold;
        width: 100%;
      }
      p:hover {
        color: #2fa9a9 !important;
      }

      img {
        height: 60px;
        width: 60px;
        border-radius: 0.5vw;
      }
    }

    .rowdata4 {
      width: 30%;
      color: #004a4a;
      font-size: 16px;
      border: 0 !important;
      display: flex;
      min-width: 30%;
      align-items: center;
      justify-content: space-between;

      .btn-royalties {
        color: #004a4a;
        background-color: white;
        border: 1px solid #004a4a;
        border-radius: 12px;
        font-weight: 700;
        font-size: 14px;
        padding: 4px 12px 4px 12px;
      }

      .btn-royalties:hover {
        color: white !important;
        background-color: #004a4a !important;
      }

      i,
      .btn-royalties {
        cursor: pointer;
      }
    }
  }

  .edituserlist {
    background: #e5ecec 0% 0% no-repeat padding-box;
    box-shadow: 0px 11px 21px #00000029;
    border-radius: 30px;
    opacity: 1;
    margin: 0.5vw 0;
    width: 100%;
    min-height: 16vw;
    padding: 2%;

    .textareaedituser {
      width: 98%;

      background: #fff;
      border-radius: 8px;

      display: flex;
      margin: 1%;

      label {
        color: #008181;
        font-size: 22px;
        font-family: TelegrafUltraBold;
        padding: 7% 15px;
        width: -moz-fit-content;
        width: unset;
        width: inherit;
        max-width: 27%;
      }

      .divider {
        background: #0000001a;
        width: 1px;
      }

      textarea {
        border: none;
        outline: none;
        color: #858585;
        width: 100%;
        margin: 1%;
        font-size: 25px;
        font-family: TelegrafUltraLight;
        max-height: 10vw;
      }
    }

    .heading {
      display: flex;

      p {
        font-size: 30px;
        font-family: TelegrafUltraLight;
        color: #004a4a;
      }

      .savebutton {
        width: 5vw;
        height: 2vw;
        padding: 5px;
        background: #004a4a 0% 0% no-repeat padding-box;
        border: none;
        border-radius: 40px;
        opacity: 1;
        font-size: 16px;
        font-family: TelegrafUltraBold;
        color: #fff;
        margin-left: auto;
      }
    }

    .edituserdetails {
      display: flex;

      .imageselector {
        width: 11vw;
        height: 9vw;
        background: #b9dddd 0% 0% no-repeat padding-box;
        border: 10px dashed #008181;
        opacity: 1;
        margin-right: 1%;
        object-fit: cover;
        .custom-file-upload {
          width: 100%;
          height: 100%;
          border: 0px dashed #029b9b31;
          opacity: 1;
          color: #fff;
          font-family: TelegrafUltraBold;
        }

        p {
          font-size: 22px;
          color: #fff;
          font-family: TelegrafUltraBold;
          text-align: center;
          width: 150px;
          text-align: justify;
          margin: 0 1%;
        }

        input[type="file"] {
          display: none;
        }

        .uploadsymbol {
          transform: translate(0%, -50%);
          position: relative;
          top: 50%;
        }
        .uploadedimage {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .edituserfields {
        width: 100%;

        .textfieldedituser {
          width: 98%;
          height: 3vw;
          background: #fff;
          box-shadow: 0px 4px 12px #0000001a;
          border-radius: 8px;
          opacity: 1;
          display: flex;
          margin: 1%;

          label {
            color: #008181;
            font-size: 25px;
            font-family: TelegrafUltraBold;
            padding: 10px 20px;
          }

          .divider {
            background: #858585;
            width: 1px;
          }

          input {
            border: none;
            outline: none;
            color: #858585;
            width: 85%;
            margin: 0 1%;
            font-size: 25px;
            font-family: TelegrafUltraLight;
          }
        }

        .flexrows {
          display: flex;
        }
      }
    }
  }
}
