.userlist {
  width: 100%;
  padding: 1% 3%;
  overflow: scroll;
  height: 100vh;

  .pageheading {
    margin-top: 3vw;
    display: flex;
    h2 {
      font-size: 40px;
      color: #585858;
      font-family: "TelegrafUltraBold";
    }

    .mainbuttons {
      color: #fff;
      margin-left: auto;
      font-family: TelegrafUltraBold;
      font-size: 22px;

      .create {
        width: 8vw;
        height: auto;
        padding: 5px;
        background: #b9dddd;
        box-shadow: 0px 4px 12px #0000001c;
        border-radius: 1vw;
        opacity: 1;
        border: 0;
        color: #004a4a;
      }
      .bulk {
        width: 5vw;
        height: auto;
        padding: 5px;
        background: #004a4a;
        box-shadow: 0px 4px 12px #0000001c;
        border-radius: 1vw;
        opacity: 1;
        border: 0;
        color: #fff;
        margin: 1vw;
      }
    }
  }

  .tabs {
    ul {
      display: flex;
      width: 50%;
      list-style: none;
      color: #ceced3;
      font-size: 24px;
      font-family: TelegrafRegular;
      justify-content: space-between;
      padding: 0;
      li {
        cursor: pointer;
        :hover {
          color: #008181 !important;
        }

        .active {
          color: #008181 !important;
        }
      }
    }

    .search {
      width: 13vw;
      height: 2vw;
      border: 1px solid #008181;
      border-radius: 0.2vw;
      font-size: 15px;
      padding: 10px 10px;
      position: relative;
      left: 96%;
      outline: none;
    }
  }
}
