.info-contests-container {
  .contentlisttable {
    .rowdata1_contests_info,
    .rowdata2_contests_info,
    .rowdata3_contests_info,
    .rowdata4_contests_info,
    .rowdata5_contests_info,
    .rowdata6_contests_info {
      width: 15%;
    }

    .rowdata3_contests_info{

      a{
        color:black;
      }

      a:hover {
        color: darkorange;
      }
      a:link {
        text-decoration: none;
      }

      a:visited {
        text-decoration: none;
      }
    }




    .rowdata6_contests_info{
      button{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        height: min-content;

        img{
        filter:invert(100)
        }
      }

      button:hover{
        img{
          filter:invert(0)
        }
      }
    }

    .rowdata2_contests_info {
      color: #004a4a;
      font-weight: bold;
    }

    .rowdata4_contests_info {
      .soundcloud_link {
        text-decoration: none;
        color: #004a4a;
      }
    }
    .rowdata5_contests_info {
      p {
        font-size: 20px;
        margin: 0;
        font-weight: bold;
      }
    }
  }

  .contests-info-partecipant {
    p {
      color: #004a4a;
      font-size: 25px;
      font-weight: bold;
    }
  }

  .note-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 570px;
    min-height: 400px;
    width: 100%;
    border-radius: 12px;
    padding: 16px 14px;
    background-color: #004a4a;
    color: #ffff;

    .btn-note-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 4px;

      button {
        border-radius: 8px;
        padding: 6px 10px;
      }
    }

    .txt-area-contest-note {
      width: 100%;
      max-width: 570px;
      min-height: 300px;
    }
  }
}

